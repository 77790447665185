//@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap');


$red: #0A95FF;
$dark: #222;

.sidebar{

}

.ekle-pencere{
  -webkit-box-shadow: -1px 10px 76px 3px rgba(0,0,0,0.75);
-moz-box-shadow: -1px 10px 76px 3px rgba(0,0,0,0.75);
box-shadow: -1px 10px 76px 3px rgba(0,0,0,0.75);

}

* {
  font-family: 'Maven Pro', sans-serif;
}

.fiyat-text{
  font-family: 'Open Sans', sans-serif;
}

.text-primary {
  color: $red !important;
}
body {
  background-color: $red;
}

.cursor-sepet {
  cursor: url(/public/sepet.svg), auto;
}

.bg-primary {
  background-color: $red !important;
}
.h-text-primary:hover {
  color: $red !important;
}
.btn-disabled:disabled{
  opacity: 0.5;
}
.parlat{
  box-shadow: 1px 2px 129px -10px rgba(255,255,255,1)
}
.button {
  background-color: rgba($red,1) !important;
  border-bottom: 2px solid $red;
  border-radius: 0.25rem;
  font-weight: 600;
  color: white;
  transition: 0.2s;

  &:disabled{
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.7;
  }

  &:active {
    transform: scale(0.9);
  }
}

.main {
  background-color: $red;
}

@media screen and (min-width:1024px){
  .screen{
    padding-left: 256px;

  }
}

@media screen and (max-width:1024px){
  .screen{
    padding: 0 10px;

  }
}

.screen{
  flex: 1;
}
.page-min{
  margin-left: 3rem;
  max-width: 660px;
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 7rem;
}
.page-big {
  margin-left: 3rem;
  width: calc(90% );
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 7rem;
}
.page {
  margin-left: 3rem;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  padding-bottom: 7rem;
  animation: in .3s;
}

.in{
  animation: in .3s;
}

.noout{
  outline:  none !important;
}

.filter-active{
  border-color: #f59e0b;
}
@keyframes in{
  from{
    opacity: 0;
    transform: translateY(100px);
  }
}

.nav {
  max-width: 590px;
  width: 100%;
  bottom: 20px;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0);
}

.search {
  background-color: $dark;
  .search-border {
    border: 2px dashed white;
  }
}

#search-input {
  color: white;
  outline: none !important;

  &::placeholder {
    color: white;
  }
}
.h-24-important{
  height: 6rem !important;
}
.skeleton {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.7) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: "";
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
.h-36-important {
  height: 9rem !important;
}

.h-full-important {
  height: 100% !important;
}

.login-box {
  max-width: 320px;
}
.show-urun-edit {
  transition: 0.2s;
  transform: scale(0);
  opacity: 0;
}

.kantin-urun:hover {
  .show-urun-edit {
    transform: scale(1);
    opacity: 1;
  }
}

.br-8{
  border-radius: 8px;
}

.b-hover{
  transition: .2s;
  &:hover{
    opacity: 0.7;
  }
}

.login-big-box{
  width: 100%;
  //max-width: 1200px;
  box-sizing: border-box;
  background-image: url(https://cdn.sstatic.net/Img/home/illo-code.svg?v=b7ee00fff9d8);
  background-repeat: no-repeat;
  background-position: 100px 80%;
  background-color:#232629 ;

  .pbox{
    padding: 20px;
    border-radius: 8px;
    width: 45%;
    position: relative;

    button{
      border-radius: 4px;
      padding: 4px 1rem;
      
    }
  }

  .pbox-turuncu{
    background-color: #FEE3CD;
    border-bottom-right-radius: 0;

    .pri{
      color:#F2740D;
    }

    &:after{
      clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
      background-color: #FEE3CD;
      width: 32px;
      height: 32px;
      position: absolute;
      top:99%;
      left: auto;
      right:0;
      transform: scaleX(-1);
      display: block;
      content: '';
      border-radius: 0 5px 0;
    }

    button{
      background-color: #F2740D;
    }
  }

  .pbox-mavi{
    background-color: #CDE9FE;
    border-bottom-left-radius: 0;
    .pri{
      color:#0A95FF;
    }
    &:after{
      clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
      background-color: #CDE9FE;
      width: 32px;
      height: 32px;
      position: absolute;
      top:99%;
      left: 0;

      display: block;
      content: '';
      border-radius: 0 5px 0;
    }

    button{
      background-color: #0A95FF;
    }
  }
}


.max-scroll{
  max-height: 360px;
  overflow-y: auto;
}

@media (min-width: 1280px) { 
  .login-big-box{
    padding: 5rem 10rem;
  }
}

@media (min-width: 1024px) { 
  .login-big-box{
    padding: 2rem 7rem;
  }
}

@media (min-width: 768px) { 
  .login-big-box{
 


  }
}


@media (max-width: 768px) { 
  .login-big-box{
    padding: 2rem 2rem;
  }

  .pbox{
    width:100% !important;


    &:after{
      display:none !important;
    }
    &:first-child{
      margin-bottom: 2rem;
    }
  }
}

.sepet-item{
  margin: 0 !important;
  .col{
    margin-top: 0 !important;
  }
  &:nth-child(even){
    background-color: #fff;
  }

  &:nth-child(odd){
    background-color: #fafafa;
  }
}
.carpan.active{
  background-color: $red !important;
}
.carpan{
  width: 33px;
  height: 33px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 1.3rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: flex;
  background-color: rgba($red,0.4);
  border: 2px solid $red;
  user-select: none;

  
  &:hover{
    cursor: pointer;
    background-color: $red;
  }
}

.orta-button{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 10px;
  border: 3px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 1;
  &:hover{
    background-color: #aaa;
  }
}

.selected-sepet-item{
  background-color: rgba($red,0.4) !important;
}

.mxh{
  max-height: 250px;
  overflow-y: auto;
}

.out-btn{
  border-width: 2px;
  border-style: solid;
  font-weight: 500;
}

.press-enter::after{
  content: "Enter: İlk ürünü ekle";
    font-weight: 500;
    line-height: 100%;
    font-style: italic;
    opacity: 0.76;
    font-size: 12px;
    position: absolute;
    display: block;
    right: 8px;
}

.shadow-table{
  -webkit-box-shadow: inset 0px 72px 21px -81px rgba(0,0,0,0.75);
  -moz-box-shadow: inset 0px 72px 21px -81px rgba(0,0,0,0.75);
  box-shadow: inset 0px 72px 21px -81px rgba(0,0,0,0.75);
}

.e-disabled{
  position: relative;

  &::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff,0.7);
    top:0;
    z-index: 10000;
    left:0;
    
  }
}

.just-admin{
  position: relative;

  &::before{
    content: "Bu özelliği sadece admin görebilir.";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: .25rem;
    background-color: rgba($color: #ef4444, $alpha: 0.60);
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: black;
  }
}

.mini-scroll{
  max-height: 160px;
  overflow-y: scroll;
}


.mobile-side{
  animation: side .3s;
  z-index: 10000000000;
}

@keyframes side{
  from{
    opacity: 0;
    transform: translate(-50%,0);
  }
}

.os{
  overflow: auto;
}

._NGZSv{
  svg{
    fill: #22c55e;
    width: 18px;
  }
}

._4GdcU:hover{
  background-color: white !important;
}

.logo-bottom{
position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0px);
    }

 /*   .login-center{
      top: 50%;
    position: absolute;
    transform: translate(0px, -50%);
    }*/


.input-focus{
  &:focus{
    outline: 2px solid $red;
  }
}