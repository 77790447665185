@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500&display=swap");
.ekle-pencere {
  -webkit-box-shadow: -1px 10px 76px 3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 10px 76px 3px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 10px 76px 3px rgba(0, 0, 0, 0.75);
}

* {
  font-family: "Maven Pro", sans-serif;
}

.fiyat-text {
  font-family: "Open Sans", sans-serif;
}

.text-primary {
  color: #0A95FF !important;
}

body {
  background-color: #0A95FF;
}

.cursor-sepet {
  cursor: url(/public/sepet.svg), auto;
}

.bg-primary {
  background-color: #0A95FF !important;
}

.h-text-primary:hover {
  color: #0A95FF !important;
}

.btn-disabled:disabled {
  opacity: 0.5;
}

.parlat {
  box-shadow: 1px 2px 129px -10px white;
}

.button {
  background-color: #0a95ff !important;
  border-bottom: 2px solid #0A95FF;
  border-radius: 0.25rem;
  font-weight: 600;
  color: white;
  transition: 0.2s;
}
.button:disabled {
  opacity: 0.5;
}
.button:hover {
  opacity: 0.7;
}
.button:active {
  transform: scale(0.9);
}

.main {
  background-color: #0A95FF;
}

@media screen and (min-width: 1024px) {
  .screen {
    padding-left: 256px;
  }
}
@media screen and (max-width: 1024px) {
  .screen {
    padding: 0 10px;
  }
}
.screen {
  flex: 1;
}

.page-min {
  margin-left: 3rem;
  max-width: 660px;
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 7rem;
}

.page-big {
  margin-left: 3rem;
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 7rem;
}

.page {
  margin-left: 3rem;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
  padding-bottom: 7rem;
  animation: in 0.3s;
}

.in {
  animation: in 0.3s;
}

.noout {
  outline: none !important;
}

.filter-active {
  border-color: #f59e0b;
}

@keyframes in {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
}
.nav {
  max-width: 590px;
  width: 100%;
  bottom: 20px;
  z-index: 10000;
  left: 50%;
  transform: translate(-50%, 0);
}

.search {
  background-color: #222;
}
.search .search-border {
  border: 2px dashed white;
}

#search-input {
  color: white;
  outline: none !important;
}
#search-input::placeholder {
  color: white;
}

.h-24-important {
  height: 6rem !important;
}

.skeleton {
  display: inline-block;
  height: 1em;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.7) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.h-36-important {
  height: 9rem !important;
}

.h-full-important {
  height: 100% !important;
}

.login-box {
  max-width: 320px;
}

.show-urun-edit {
  transition: 0.2s;
  transform: scale(0);
  opacity: 0;
}

.kantin-urun:hover .show-urun-edit {
  transform: scale(1);
  opacity: 1;
}

.br-8 {
  border-radius: 8px;
}

.b-hover {
  transition: 0.2s;
}
.b-hover:hover {
  opacity: 0.7;
}

.login-big-box {
  width: 100%;
  box-sizing: border-box;
  background-image: url(https://cdn.sstatic.net/Img/home/illo-code.svg?v=b7ee00fff9d8);
  background-repeat: no-repeat;
  background-position: 100px 80%;
  background-color: #232629;
}
.login-big-box .pbox {
  padding: 20px;
  border-radius: 8px;
  width: 45%;
  position: relative;
}
.login-big-box .pbox button {
  border-radius: 4px;
  padding: 4px 1rem;
}
.login-big-box .pbox-turuncu {
  background-color: #FEE3CD;
  border-bottom-right-radius: 0;
}
.login-big-box .pbox-turuncu .pri {
  color: #F2740D;
}
.login-big-box .pbox-turuncu:after {
  clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
  background-color: #FEE3CD;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 99%;
  left: auto;
  right: 0;
  transform: scaleX(-1);
  display: block;
  content: "";
  border-radius: 0 5px 0;
}
.login-big-box .pbox-turuncu button {
  background-color: #F2740D;
}
.login-big-box .pbox-mavi {
  background-color: #CDE9FE;
  border-bottom-left-radius: 0;
}
.login-big-box .pbox-mavi .pri {
  color: #0A95FF;
}
.login-big-box .pbox-mavi:after {
  clip-path: polygon(18px 0, 32px 0, 0 40px, 0 38px, 0 0, 18px 0);
  background-color: #CDE9FE;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 99%;
  left: 0;
  display: block;
  content: "";
  border-radius: 0 5px 0;
}
.login-big-box .pbox-mavi button {
  background-color: #0A95FF;
}

.max-scroll {
  max-height: 360px;
  overflow-y: auto;
}

@media (min-width: 1280px) {
  .login-big-box {
    padding: 5rem 10rem;
  }
}
@media (min-width: 1024px) {
  .login-big-box {
    padding: 2rem 7rem;
  }
}
@media (max-width: 768px) {
  .login-big-box {
    padding: 2rem 2rem;
  }

  .pbox {
    width: 100% !important;
  }
  .pbox:after {
    display: none !important;
  }
  .pbox:first-child {
    margin-bottom: 2rem;
  }
}
.sepet-item {
  margin: 0 !important;
}
.sepet-item .col {
  margin-top: 0 !important;
}
.sepet-item:nth-child(even) {
  background-color: #fff;
}
.sepet-item:nth-child(odd) {
  background-color: #fafafa;
}

.carpan.active {
  background-color: #0A95FF !important;
}

.carpan {
  width: 33px;
  height: 33px;
  border-radius: 4px;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 1.3rem;
  font-weight: 500;
  justify-content: center;
  align-items: center;
  line-height: 1;
  display: flex;
  background-color: rgba(10, 149, 255, 0.4);
  border: 2px solid #0A95FF;
  user-select: none;
}
.carpan:hover {
  cursor: pointer;
  background-color: #0A95FF;
}

.orta-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 10px;
  border: 3px solid white;
  border-radius: 3px;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 1;
}
.orta-button:hover {
  background-color: #aaa;
}

.selected-sepet-item {
  background-color: rgba(10, 149, 255, 0.4) !important;
}

.mxh {
  max-height: 250px;
  overflow-y: auto;
}

.out-btn {
  border-width: 2px;
  border-style: solid;
  font-weight: 500;
}

.press-enter::after {
  content: "Enter: İlk ürünü ekle";
  font-weight: 500;
  line-height: 100%;
  font-style: italic;
  opacity: 0.76;
  font-size: 12px;
  position: absolute;
  display: block;
  right: 8px;
}

.shadow-table {
  -webkit-box-shadow: inset 0px 72px 21px -81px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: inset 0px 72px 21px -81px rgba(0, 0, 0, 0.75);
  box-shadow: inset 0px 72px 21px -81px rgba(0, 0, 0, 0.75);
}

.e-disabled {
  position: relative;
}
.e-disabled::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  z-index: 10000;
  left: 0;
}

.just-admin {
  position: relative;
}
.just-admin::before {
  content: "Bu özelliği sadece admin görebilir.";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  background-color: rgba(239, 68, 68, 0.6);
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: black;
}

.mini-scroll {
  max-height: 160px;
  overflow-y: scroll;
}

.mobile-side {
  animation: side 0.3s;
  z-index: 10000000000;
}

@keyframes side {
  from {
    opacity: 0;
    transform: translate(-50%, 0);
  }
}
.os {
  overflow: auto;
}

._NGZSv svg {
  fill: #22c55e;
  width: 18px;
}

._4GdcU:hover {
  background-color: white !important;
}

.logo-bottom {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0px);
}

/*   .login-center{
     top: 50%;
   position: absolute;
   transform: translate(0px, -50%);
   }*/
.input-focus:focus {
  outline: 2px solid #0A95FF;
}

